
import {
  defineComponent, reactive, ref, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import EditorHtml from '@/core/components/Tela/EditorHtml.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarEmpresaCadastroCompartilhado from '@/components/MeuSistema/Empresas/SelecionarEmpresaCadastroCompartilhado.vue';
import SelecionarMarcador from '@/components/Cadastros/Produtos/SelecionarMarcador.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import ProdutoDadosPrincipais from '@/components/Cadastros/Produtos/ProdutoDadosPrincipais.vue';
import ProdutoCodigos from '@/components/Cadastros/Produtos/ProdutoCodigos.vue';
import ProdutoUnidades from '@/components/Cadastros/Produtos/ProdutoUnidades.vue';
import ProdutoPesoMedidas from '@/components/Cadastros/Produtos/ProdutoPesoMedidas.vue';
import ProdutoFornecedores from '@/components/Cadastros/Produtos/ProdutoFornecedores.vue';
import ProdutoImagens from '@/components/Cadastros/Produtos/ProdutoImagens.vue';
import ProdutoCaracteristicas from '@/components/Cadastros/Produtos/ProdutoCaracteristicas.vue';
import ProdutoDadosEspecificos from '@/components/Cadastros/Produtos/ProdutoDadosEspecificos.vue';
import ProdutoSemelhantes from '@/components/Cadastros/Produtos/ProdutoSemelhantes.vue';
import ProdutoEstoque from '@/components/Cadastros/Produtos/ProdutoEstoque.vue';
import ProdutoCustos from '@/components/Cadastros/Produtos/ProdutoCustos.vue';
import ProdutoPrecos from '@/components/Cadastros/Produtos/ProdutoPrecos.vue';
import ProdutoAdicionarVariacao from '@/components/Cadastros/Produtos/ProdutoAdicionarVariacao.vue';
import ProdutoEditarVariacao from '@/components/Cadastros/Produtos/ProdutoEditarVariacao.vue';
import DisplayTelaPersonalizada from '@/components/MeuSistema/PersonalizacoesTelas/DisplayTelaPersonalizada.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoProduto from '@/servicos/Cadastros/Produtos/ServicoProduto';
import {
  IProduto, IProdutoCategoriaProduto, IProdutoCombustivel, IProdutoCusto, IProdutoDefinicao, IProdutoEmpresa, IProdutoMarcador, IProdutoMedicamento,
} from '@/models/Entidades/Cadastros/Produtos/IProduto';
import { ICaracteristica } from '@/models/Entidades/Cadastros/Produtos/ICaracteristica';
import ServicoCaracteristica from '@/servicos/Cadastros/Produtos/ServicoCaracteristica';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ITabelaPrecoItemProduto } from '@/models/Entidades/Cadastros/Precificacao/ITabelaPreco';
import { ETipoRastreabilidade } from '@/models/Enumeradores/Cadastros/Produtos/ETipoRastreabilidade';
import { ETipoLancamentoRastreabilidade } from '@/models/Enumeradores/Cadastros/Produtos/ETipoLancamentoRastreabilidade';
import { ICampoPersonalizado, IPersonalizacaoTela } from '@/models/Entidades/MeuSistema/PersonalizacoesTelas/IPersonalizacaoTela';
import ServicoPersonalizacaoTela from '@/servicos/MeuSistema/ServicoPersonalizacaoTela';
import storeSistema from '@/store/storeSistema';
import { ETipoCaracteristica } from '@/models/Enumeradores/Cadastros/Produtos/ETipoCaracteristica';

export default defineComponent({
  name: 'ProdutoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
    produtoPreCadastro: {
      type: Object as () => IProduto,
    },
  },
  components: {
    Icone,
    Card,
    MensagemSemDados,
    EditorHtml,
    RequisicaoModal,
    SelecionarData,
    SelecionarMarcador,
    SelecionarEmpresaCadastroCompartilhado,
    ProdutoDadosPrincipais,
    ProdutoCodigos,
    ProdutoUnidades,
    ProdutoPesoMedidas,
    ProdutoFornecedores,
    ProdutoImagens,
    ProdutoCaracteristicas,
    ProdutoDadosEspecificos,
    ProdutoSemelhantes,
    ProdutoEstoque,
    ProdutoPrecos,
    ProdutoCustos,
    ProdutoAdicionarVariacao,
    ProdutoEditarVariacao,
    DisplayTelaPersonalizada,
  },
  emits: ['sincronizarRegistro', 'cadastroConcluido', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const refAdicionarVariacao = ref<InstanceType<typeof ProdutoAdicionarVariacao>>();
    const refEditarVariacao = ref<InstanceType<typeof ProdutoEditarVariacao>>();

    const {
      telaBase, obterPermissoes, preencherPermissoesDados, preencherEmpresasComEstrategiaPermissaoDados, defineEmpresasSelecionadasCadastroCompartilhado,
      apresentarMensagemAlerta, apresentarMensagemSucesso, filtrarPermissaoDadosUsuarioMultiEmpresas,
      verificaConceitoParaApresentarEmpresas,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
      defineNovaOperacao, defineTextoPadraoBotoes, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoProduto = new ServicoProduto();
    const servicoPersonalizacaoTela = new ServicoPersonalizacaoTela();
    telaBase.identificadorRecurso = 'CADASTRO_PRODUTOS';
    telaBase.identificadorPermissao = 'PER_CADASTRO_PRODUTOS';

    const state = reactive({
      windowWidth: window.innerWidth,
      produto: {} as IProduto,
      categoriasSelecionadas: [] as number[],
      marcadoresSelecionados: [] as string[],
      caracteristicasCadastradas: [] as ICaracteristica[],
      custosPredefinidos: [] as IProdutoCusto[],
      precosPredefinidos: [] as ITabelaPrecoItemProduto[],
      codigoPlanoContaCategoria: 0,
      personalizacaoTela: {} as IPersonalizacaoTela,
      exibirCamposPersonalizados: false,
    });

    state.caracteristicasCadastradas = [];
    state.categoriasSelecionadas = [];
    state.custosPredefinidos = [];
    state.precosPredefinidos = [];

    function objetoInicial() {
      state.custosPredefinidos = [];
      state.precosPredefinidos = [];
      state.marcadoresSelecionados = [];
      state.categoriasSelecionadas = [];

      state.produto = {} as IProduto;
      state.produto.codigo = 0;
      state.produto.descricao = '';
      state.produto.descricaoCompleta = '';
      state.produto.codigoUnidade = 0;
      state.produto.tipoProduto = 0;
      state.produto.fatorUnidadePrincipal = 1;
      state.produto.codigoCest = '';
      state.produto.genero = '';
      state.produto.possuiVariacao = false;
      state.produto.combustivel = false;
      state.produto.combustivelDetalhe = {} as IProdutoCombustivel;
      state.produto.medicamento = false;
      state.produto.medicamentoDetalhe = {} as IProdutoMedicamento;
      state.produto.dataCadastro = '';
      state.produto.dataAlteracao = '';
      state.produto.ativo = true;
      state.produto.empresas = [];
      state.produto.categorias = [];
      state.produto.definicoes = [];
      const definicaoPadrao: IProdutoDefinicao = {} as IProdutoDefinicao;
      definicaoPadrao.tipoRastreabilidade = ETipoRastreabilidade.Nenhum;
      definicaoPadrao.tipoLancamentoRastreabilidade = ETipoLancamentoRastreabilidade.Manual;
      definicaoPadrao.codigo = 0;
      definicaoPadrao.ativo = true;
      definicaoPadrao.codigoInterno = '';
      definicaoPadrao.bloquearNovasCompras = false;
      definicaoPadrao.custos = [];
      definicaoPadrao.precos = [];
      definicaoPadrao.codigos = [];
      definicaoPadrao.unidades = [];
      definicaoPadrao.fornecedores = [];
      definicaoPadrao.imagens = [];
      definicaoPadrao.caracteristicasVariacao = [];
      state.produto.definicoes.push(definicaoPadrao);
      state.produto.caracteristicas = [];
      state.produto.produtosSemelhantes = [];
    }

    async function obterPersonalizacaoTela() {
      state.personalizacaoTela = await servicoPersonalizacaoTela.obterPeloRecurso(telaBase.identificadorRecurso, storeSistema.getters.codigoEmpresaOperacao());
      state.exibirCamposPersonalizados = UtilitarioGeral.valorValido(state.personalizacaoTela);
    }

    async function comportamentoVariacoes() {
      if (state.produto.possuiVariacao) {
        state.custosPredefinidos = UtilitarioGeral.instanciaObjetoLocal(state.produto.definicoes[0].custos);
        for (let index = 0; index < state.custosPredefinidos.length; (index += 1)) {
          state.custosPredefinidos[index].codigo = 0;
          state.custosPredefinidos[index].codigoProdutoDefinicao = 0;
        }

        state.precosPredefinidos = UtilitarioGeral.instanciaObjetoLocal(state.produto.definicoes[0].precos);
        for (let index = 0; index < state.precosPredefinidos.length; (index += 1)) {
          state.precosPredefinidos[index].codigo = 0;
          state.precosPredefinidos[index].codigoProdutoDefinicao = 0;
        }
        state.produto.definicoes = [];
        if (!UtilitarioGeral.validaLista(state.caracteristicasCadastradas)) {
          state.caracteristicasCadastradas = await new ServicoCaracteristica().obterTodasCaracteristicas();
        }
      } else if (state.produto.definicoes.length > 1) {
        const primeiraDefinificao = UtilitarioGeral.instanciaObjetoLocal(state.produto.definicoes[0]);
        state.produto.definicoes = [];
        state.produto.definicoes.push(primeiraDefinificao);
      } else {
        state.produto.definicoes = [];
        const definicaoPadrao: IProdutoDefinicao = {} as IProdutoDefinicao;
        definicaoPadrao.tipoRastreabilidade = ETipoRastreabilidade.Nenhum;
        definicaoPadrao.tipoLancamentoRastreabilidade = ETipoLancamentoRastreabilidade.Manual;
        definicaoPadrao.codigo = 0;
        definicaoPadrao.ativo = true;
        definicaoPadrao.codigoInterno = '';
        definicaoPadrao.bloquearNovasCompras = false;
        definicaoPadrao.custos = [];
        definicaoPadrao.precos = [];
        definicaoPadrao.codigos = [];
        definicaoPadrao.unidades = [];
        definicaoPadrao.fornecedores = [];
        definicaoPadrao.imagens = [];
        definicaoPadrao.caracteristicasVariacao = [];
        state.produto.definicoes.push(definicaoPadrao);
      }
    }

    function preparaPersistenciaCategorias() {
      const produtoCategorias: IProdutoCategoriaProduto[] = [];
      if (state.categoriasSelecionadas.length > 0) {
        state.categoriasSelecionadas.forEach((codigoCategoria) => {
          const categoriaExistente = state.produto.categorias.find((c) => c.codigoCategoriaProduto === codigoCategoria);
          if (categoriaExistente !== undefined) {
            produtoCategorias.push(categoriaExistente);
          } else {
            const produtoCategoria: IProdutoCategoriaProduto = {
              codigo: 0, codigoProduto: state.produto.codigo, codigoCategoriaProduto: codigoCategoria,
            };
            produtoCategorias.push(produtoCategoria);
          }
        });
      }
      state.produto.categorias = produtoCategorias;
    }

    function preparaPersistenciaMarcadores() {
      const produtoMarcadores: IProdutoMarcador[] = [];
      if (state.marcadoresSelecionados.length > 0) {
        state.marcadoresSelecionados.forEach((marcador) => {
          const marcadorExistente = state.produto.marcadores.find((c) => c.marcador === marcador);
          if (marcadorExistente !== undefined) {
            produtoMarcadores.push(marcadorExistente);
          } else {
            const produtoMarcador: IProdutoMarcador = {
              codigo: 0, codigoProduto: state.produto.codigo, marcador,
            };
            produtoMarcadores.push(produtoMarcador);
          }
        });
      }
      state.produto.marcadores = produtoMarcadores;
    }

    function preparaPersistenciaEmpresas() {
      const produtoEmpresas: IProdutoEmpresa[] = [];
      if (telaBase.empresasSelecionadas.length > 0) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          const empresaExistente = state.produto.empresas.find((c) => c.codigoEmpresa === codigoEmpresa);
          if (empresaExistente !== undefined) {
            produtoEmpresas.push(empresaExistente);
          } else {
            const produtoEmpresa: IProdutoEmpresa = { codigo: 0, codigoProduto: state.produto.codigo, codigoEmpresa };
            produtoEmpresas.push(produtoEmpresa);
          }
        });
      }
      state.produto.empresas = produtoEmpresas;
    }

    function preparaPersistenciaCamposPersonalizados() {
      if (state.exibirCamposPersonalizados) {
        state.produto.camposPersonalizados = [] as ICampoPersonalizado[];
        state.personalizacaoTela.gruposCamposPersonalizados.forEach((g) => {
          g.campos.forEach((c) => {
            state.produto.camposPersonalizados.push(c);
          });
        });
      }
    }

    function adaptarCaracteristicas() {
      state.produto.caracteristicas.forEach((c, index) => {
        if (c.tipo === ETipoCaracteristica.Numero || c.tipo === ETipoCaracteristica.Decimal) {
          const valor = Number(c.caracteristicaPersonalizada);
          state.produto.caracteristicas[index].caracteristicaPersonalizada = String(valor);
        }
      });
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      preparaPersistenciaCategorias();
      preparaPersistenciaEmpresas();
      preparaPersistenciaMarcadores();
      preparaPersistenciaCamposPersonalizados();
      adaptarCaracteristicas();
      apresentarBarraProgresso();
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir || props.operacao.codigoRegistroDuplicar > 0) {
        retorno = await servicoProduto.incluir(state.produto);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoProduto.alterar(state.produto);
      }

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
          emit('cadastroConcluido', retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          telaBase.empresasSelecionadas = [props.operacao.empresaSelecionada];
          defineNovaOperacao(props.operacao);
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      telaBase.empresasSelecionadas = [];
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (UtilitarioGeral.objetoValido(props.produtoPreCadastro)) {
          state.produto = UtilitarioGeral.instanciaObjetoLocal(props.produtoPreCadastro);
          servicoProduto.requisicaoSistema();
          telaBase.empresasSelecionadas = [];
          if (state.produto.empresas.length > 0) {
            state.produto.empresas.forEach((produtoEmpresa) => {
              telaBase.empresasSelecionadas.push(produtoEmpresa.codigoEmpresa);
            });
          }
        } else {
          if (props.operacao.listaPermissoesDados.length > 0) {
            await preencherPermissoesDados(props.operacao.listaPermissoesDados);
          } else {
            await obterPermissoes(ETipoPermissao.Dados);
          }

          await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);
          telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        }

        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados);
        await obterPersonalizacaoTela();
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar || props.operacao.codigoRegistroDuplicar > 0) {
          if (props.operacao.codigoRegistroDuplicar > 0) {
            state.produto = await servicoProduto.obter(props.operacao.codigoRegistroDuplicar, props.operacao.empresaSelecionada);
            state.produto.codigo = 0;
            state.produto.definicoes.forEach((d, indexD) => {
              state.produto.definicoes[indexD].codigoInterno = '';
              state.produto.definicoes[indexD].codigoProduto = 0;
            });
          } else {
            state.produto = await servicoProduto.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
          }

          if (!state.produto.combustivel) { state.produto.combustivelDetalhe = {} as IProdutoCombustivel; }

          if (!state.produto.medicamento) { state.produto.medicamentoDetalhe = {} as IProdutoMedicamento; }

          if (state.produto.possuiVariacao) {
            if (!UtilitarioGeral.validaLista(state.caracteristicasCadastradas)) {
              state.caracteristicasCadastradas = await new ServicoCaracteristica().obterTodasCaracteristicas();
            }
          }

          telaBase.empresasSelecionadas = [];
          if (state.produto.empresas.length > 0) {
            state.produto.empresas.forEach((produtoEmpresa) => {
              telaBase.empresasSelecionadas.push(produtoEmpresa.codigoEmpresa);
            });
          }

          state.categoriasSelecionadas = [];
          if (state.produto.categorias.length > 0) {
            state.produto.categorias.forEach((produtoCategoria) => {
              state.categoriasSelecionadas.push(produtoCategoria.codigoCategoriaProduto);
            });
          }

          state.marcadoresSelecionados = [];
          if (state.produto.marcadores.length > 0) {
            state.produto.marcadores.forEach((produtoMarcador) => {
              state.marcadoresSelecionados.push(produtoMarcador.marcador);
            });
          }
        } else {
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
        }

        if (UtilitarioGeral.objetoValido(props.produtoPreCadastro)) {
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
          modalBase.textoBotaoSalvarNovo = '';
          telaBase.permissaoDados.incluir = true;
          telaBase.permissaoDados.visualizar = true;
        } else {
          telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        }
        verificaConceitoParaApresentarEmpresas();
      }
      telaBase.carregando = false;
    });

    function adicionarNovaVariacao() {
      if (refAdicionarVariacao.value) {
        if (!UtilitarioGeral.valorValido(state.produto.descricao)) {
          apresentarMensagemAlerta('É necessário informar a descrição do produto antes de criar a variação!');
          return;
        }
        if (UtilitarioGeral.objetoValido(state.produto.codigoUnidade)) {
          if (!(state.produto.codigoUnidade > 0)) {
            apresentarMensagemAlerta('É necessário definir a unidade principal do produto antes de criar a variação!');
            return;
          }
        }
        refAdicionarVariacao.value.adicionarVariacao(state.custosPredefinidos, state.precosPredefinidos);
      }
    }

    function editarVariacao(produtoDefinicao: IProdutoDefinicao, index: number) {
      if (refEditarVariacao.value) {
        refEditarVariacao.value.editarVariacao(produtoDefinicao, index);
      }
    }

    return {
      telaBase,
      props,
      servicoProduto,
      refAdicionarVariacao,
      modalBase,
      state,
      salvar,
      objetoInicial,
      EPermissaoDados,
      adicionarNovaVariacao,
      comportamentoVariacoes,
      refEditarVariacao,
      editarVariacao,
      UtilitarioGeral,
    };
  },
});
